import React from "react";
import { config, useSpring } from "react-spring";
import Layout from "../components/layout";
import { AnimatedBox } from "../elements";
import SEO from "../components/SEO";
import Modal from "../components/modal";

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 }
  });

  return (
    <Layout>
      <SEO title="About | Garrett"
           desc="Hi. I'm Garrett! I create imagery for brands in the overland, outdoor and off road industries.." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Hi, I'm Garrett</h1>
        <p>
          I'm a photographer, software engineer, and avid outdoorsman based out of San Diego.
          When I'm not working on creating new apps or shooting photos, you'll find me in the
          woods exploring with our trusty Land Rover, surfing, fishing or snowboarding. You can find some of my web
          design work <a href="https://garrett-hughes.com?utm_source=website&utm_medium=photography"
                         target="_blank">here</a>.
        </p>
        <p style={{ marginTop: 30 }}>
          Previous clients include Lamborghini, Ferrari, Goal Zero, Yeti, Dometic, Treeline Outdoors, Wilco Offroad, GSI
          Outdoors, Rocky Road Outfitters, Howler Brothers, Prana, Stance Socks, Leki, Traeger and more.
        </p>
      </AnimatedBox>
      <Modal />
    </Layout>
  );
};

export default About;
